import type { FC } from "react";
import dynamic from "next/dynamic";
import LinkList from "@/components/List/LinkList/LinkList";
import { LazyHydrateContainer } from "@/hoc/LazyHydrate";
import { useWindowSize } from "@/hooks/useWindowSize";
import { ListGroup } from "@/layouts/Default/Footer/StyledFooter";
import { getBreakpointVal } from "@/styles/utils/Utils";
import { breakpoints } from "@/styles/utils/vars";
import { Button } from "@/ui/Button/Button";
import { ButtonSizeAlias } from "@/ui/Button/constants";
import { cssButtonClean } from "@/ui/Button/StyledButton";
import { Link } from "@/ui/Link";
import { LinkVariants } from "@/ui/Link/constants";
import { Modal } from "@/ui/Modal/Modal";
import { Popover } from "@/ui/Popover/Popover";
import { CONTACTS, CONTACTS_ALIAS } from "@/utils/constants";
import { cssButtonRecall, StyledCallContainer, StyledCallTitle } from "./StyledCallInfo";
const Recall = dynamic(() => import("@/components/Forms/Recall").then(mod => mod.Recall), {
  ssr: false
});
const contactsFormatter = Object.entries(CONTACTS).filter(([alias]) => alias !== CONTACTS_ALIAS.CHAT).map(([, v]) => v);
export const CallInfo: FC = () => {
  const {
    width
  } = useWindowSize();
  const isResponsMode = width !== undefined && width <= getBreakpointVal(breakpoints.lg);
  return <StyledCallContainer>
      <Popover offset={[0, -2]} withHover={!isResponsMode} placement={"bottom-start"} disclosure={<Button className={cssButtonClean}>
            <StyledCallTitle>
              {isResponsMode ? CONTACTS.phone.title : <Link href={CONTACTS.phone.path}>{CONTACTS.phone.title}</Link>}
            </StyledCallTitle>
          </Button>}>
        <ListGroup>
          <LinkList items={contactsFormatter} variant={LinkVariants.BLACK_TO_PURPLE} />
        </ListGroup>
      </Popover>

      <Modal closeMode={"destroy"} disclosure={<Button className={cssButtonRecall} size={ButtonSizeAlias.SMALL} isClean>
            Заказать звонок
          </Button>} title={"Заказать звонок"}>
        <LazyHydrateContainer>
          <Recall />
        </LazyHydrateContainer>
      </Modal>
    </StyledCallContainer>;
};
CallInfo.displayName = "CallInfo";